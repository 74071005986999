/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from "react";

import assets from "../../../../../assets";
import { SelectInput, SpanFloater } from "../../ArtistProfile.styled";
import { popToast } from "../../../../../components/Toast/Toast";
import { postFormData, putFormData } from "../../../../../util/apiConfig";
import FloatingLabeledInput from "../../../../../components/FloatingLabeledInput/FloatingLabeledInput";
import StyledButton from "../../../../../components/StyledButton/StyledButton";
import { AddNewEventBody, AddNewEventFooter, AddNewEventImage, AddNewEventImageWrap } from "../EventContainer/AddNewEventModal/AddNewEventModal.styled";
import { isEmpty } from "lodash";

const MediaPublicationModal = ({ onLoad, onToggleModel, type, data, artistId }) => {
	const [values, setValues] = useState();
	const [media, setMedia] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const year = new Date().getFullYear();
	const yearList = Array.from(new Array(121), (val, index) => year - index);

	// On change inputs
	const updateValues = (newValue) => {
		setValues({ ...values, ...newValue });
	};

	useEffect(() => {
		if (type === "edit") {
			updateValues({
				title: data.title ? data.title : "",
				author: data.author ? data.author : "",
				publishedby: data.publishedBy ? data.publishedBy : "",
				year: data.year ? data.year : "",
				type: data.type ? data.type : "",
				pagelink: data.reference ? data.reference : "",
				ManagedArtistId: artistId,
			});
			let mediaObj = {
				media: "",
				mediaResult: data.imageURL,
			};
			setMedia(mediaObj);
		} else {
			updateValues({
				title: "",
				author: "",
				publishedby: "",
				year: "",
				type: "",
				pagelink: "",
			});
		}

		if (yearList) {
			var yearOption = [];
			yearList.map((year, index) => {
				yearOption.push({
					name: year,
					value: year,
				});
			});
		}
	}, []);

	const attach_image = useRef(null);

	// Checking if uploading file is valid
	const isFileValid = (file) => {
		// TODO : Validation?
		if (file.size > 104857600) {
			popToast.show("Media size should be below 200MB. Try choosing again");
			return false;
		}
		if (!file.type.startsWith("image/")) {
			popToast.show("Try uploading a valid image file");
			return false;
		}
		return true;
	};

	// On select image
	const onImageSelect = (e, type) => {
		if (values && values.eventImage) values.eventImage = null;
		e.preventDefault();
		let file = e.target.files[0];
		e.target.value = null;
		if (file && isFileValid(file)) {
			let reader = new FileReader();

			reader.onloadend = () => {
				let mediaObj = {
					media: file,
					mediaResult: reader.result,
				};
				setMedia(mediaObj);
			};
			reader.readAsDataURL(file);
		}
	};

	const isValidForm = () => {
		if (isEmpty(values.title)) {
			popToast.show("Title is mandatory", "error");
			return false;
		} else if (isEmpty(values.author)) {
			popToast.show("Author is mandatory", "error");
			return false;
		} else if (isEmpty(values.publishedby)) {
			popToast.show("Published by is mandatory", "error");
			return false;
		} if (!values.year) {
			popToast.show("Year is mandatory", "error");
			return false;
		} else if (isEmpty(values.type)) {
			popToast.show("Type is mandatory", "error");
			return false;
		} else if (!media) {
			popToast.show("Please upload a image", "error");
			return false;
		}
		return true;
	}

	const onClickSave = () => {
		if (isValidForm()) {
			setIsLoading(true);
			// values.eventImage = media.media;
			console.log(values);
			let token = localStorage.getItem('authToken');
			var formdata = new FormData();

			formdata.append("Image", media ? media.media : null);
			formdata.append("Title", values.title);
			formdata.append("Type", values.type);
			formdata.append("PublishedBy", values.publishedby);
			formdata.append("Reference", values.pagelink);
			formdata.append("Year", values.year);
			formdata.append("Author", values.author);
			formdata.append("ManagedArtistId", artistId);
			if (type === "edit") {
				putFormData(`UserManagment/api/Artists/UpdatePublications?publicationId=${data._id}`, formdata, token).then((responseJson) => {
					if (responseJson.resultCode === 1) {

						popToast.show("Update Successfully");
						onLoad();
						onToggleModel();
					} else {
						popToast.show(responseJson.error.message);
					}
					setIsLoading(false);
				});
			} else {
				postFormData("UserManagment/api/Artists/AddPublications", formdata, token).then((responseJson) => {
					if (responseJson.resultCode === 1) {

						popToast.show("Added Successfully");
						onLoad();
						onToggleModel();
					} else {
						popToast.show(responseJson.error.message);
					}
					setIsLoading(false);
				});
			}
		}
	};

	return (
		<>
			{values && <AddNewEventBody>
				<AddNewEventImageWrap onClick={(e) => attach_image.current.click()}>
					<AddNewEventImage notPlaceholder={media} src={media ? media.mediaResult : assets.images.addPhoto} alt="add" />
					<input
						type="file"
						style={{ display: "none" }}
						accept=".bmp, .gif, .ico, .jpeg, .jpg, .png, .svg, .tif, .tiff, .jiff, .jfif, .pjpeg, .pjp"
						onChange={(e) => onImageSelect(e, "image")}
						ref={attach_image}
					/>
				</AddNewEventImageWrap>
				<FloatingLabeledInput label={"Title*"} value={values.title} onChange={(e) => updateValues({ title: e.target.value })} />
				<FloatingLabeledInput
					label={"Author*"}
					value={values.author}
					onChange={(e) => updateValues({ author: e.target.value })}
				/>
				<FloatingLabeledInput
					label={"Published by*"}
					value={values.publishedby}
					onChange={(e) => updateValues({ publishedby: e.target.value })}
				/>

				<div style={{ position: "relative", width: "100%" }}>
					<SpanFloater>{"Year*"}</SpanFloater>
					<SelectInput
						style={{
							height: 40,
							width: "100%",
							marginTop: 15,
						}}
						showSearch
						optionFilterProp="children"
						value={values.year}
						onChange={(e) => {
							updateValues({ year: e });
						}}
					>
						{yearList.map((year, index) => {
							return (
								<option key={`year${index}`} value={year}>
									{year}
								</option>
							);
						})}
					</SelectInput>
				</div>

				<div style={{ position: "relative", width: "100%" }}>
					<SpanFloater>{"Type*"}</SpanFloater>
					<SelectInput
						style={{
							height: 40,
							width: "100%",
							marginTop: 15,
						}}
						showSearch
						optionFilterProp="children"
						value={values.type}
						onChange={(e) => {
							updateValues({ type: e });
						}}
					>
						<option value="Book">Book</option>
						<option value=" Catalogue Raisonné"> Catalogue Raisonné</option>
						<option value="Catalogue">Catalogue</option>
						<option value="Article">Article</option>
						<option value="Interview">Interview</option>
						<option value="Reportage">Reportage</option>
					</SelectInput>
				</div>

				<FloatingLabeledInput
					label={"Page/Link"}
					value={values.pagelink}
					onChange={(e) => updateValues({ pagelink: e.target.value })}
				/>
			</AddNewEventBody>}
			<AddNewEventFooter>
				<StyledButton
					loading={isLoading}
					disabled={isLoading}
					variant="invert"
					height="40px"
					width="10rem"
					fontSize="14px"
					margin="auto 0"
					onClick={() => onClickSave()}
				>
					{isLoading ? "Saving" : "Save"}
				</StyledButton>
			</AddNewEventFooter>
		</>
	);
};
export default MediaPublicationModal;
