import React, { useEffect, useState, useRef } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import assets from "../../../../../assets";
import { TitleHeading, SocialMediaItem, MainSubmitButton, AddNewProfileEventImageWrap, AddNewEventImage, SpanFloater, SelectInput } from "../../ArtistProfile.styled";
import { isEmpty } from "lodash";

import { Tooltip } from "antd";
import { FormInput, FormRow, FormTextarea } from "../../Artist.styled";
import FormikInput from "../../../../../components/FormikInput/FormikInput";
import { popToast } from "../../../../../components/Toast/Toast";
import { putFormData } from "../../../../../util/apiConfig";
import ButtonSet from "../../../../../components/ButtonSet/ButtonSet";
import { ManageArtistsService } from "../../../ManageArtists.service";
import { useSelector } from "react-redux";
import DisplayHeader from "../DisplayHeader";
import FormikEditor from "../../../../../components/TextEditor/FormikEditor";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

export const ProfileTab = ({ getArtistDetails, userId, artistDetails, onTabChange }) => {
	const nonWhiteSpacesRegex = /.*\S.*/;
	const year = new Date().getFullYear();
	const yearList = Array.from(new Array(121), (val, index) => year - index);

	const { countriesList } = useSelector(state => state.countriesTaxReducer);
	const [buttonTypes, setButtonTypes] = useState([]);
	const [mediumButtonTypes, setMediumButtonTypes] = useState([]);
	const [buttonlist, setbuttonlist] = useState([]);
	const [buttonListMedium, setButtonListMedium] = useState([]);
	const [baseCountry, setBaseCountry] = useState();
	const [media, setMedia] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [buttonType, setButtonType] = useState("");
	const [birthYear, setBirthYear] = useState();
	const [birthCountry, setBirthCountry] = useState();
	const [overView, setOverView] = useState();
	const [editorState, setEditorState] = useState(null);
	useEffect(() => {
		if (artistDetails) {
			setBaseCountry(artistDetails.place);
			setBirthYear(artistDetails.birthYear);
			setBirthCountry(artistDetails.birthCountry);
			setOverView(artistDetails.overView);
			setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(artistDetails.overView ? artistDetails.overView : ""))));
		}
	}, [artistDetails])

	const selectedStyleList = (buttonlist) => {
		setbuttonlist(buttonlist);
	};

	const selectedMediumList = (buttonListMedium) => {
		setButtonListMedium(buttonListMedium);
	};

	useEffect(() => {
		onLoadPage();

	}, []);

	const onLoadPage = () => {
		let body = {
			PropertyNames: ["MEDIUM"],
		};
		ManageArtistsService.getArtworkPrototypes(body).then(resp => {
			if (resp && resp.data) {
				let buttons = [];
				if (resp.data && !isEmpty(resp.data.artworkMedium)) {
					resp.data.artworkMedium.map((item) => {
						let obj = {
							label: item.name,
							value: item._id,
						};
						buttons.push(obj);
					});
				}
				setMediumButtonTypes(buttons);
			}
		})

		ManageArtistsService.getArtistTypes(userId).then(resp => {
			if (resp && resp.data) {
				let buttons = [];
				if (resp.data && resp.data.length !== 0) {
					resp.data.map((item) => {
						let obj = {
							label: item.artistType,
							value: item._id,
						};
						buttons.push(obj);
					});
				}
				setButtonTypes(buttons);
			}
		})
	}


	const onSubmitProfile = (values) => {
		setIsLoading(true);

		var formdata = new FormData();
		formdata.append("ArtistId", userId);
		formdata.append("Name", values.fullName);
		formdata.append("Place", baseCountry);
		formdata.append("AliasName", values.aliasName);
		formdata.append("ArtistId", userId);
		formdata.append("ArtworkImage", media !== null ? media.media : "");
		formdata.append("ArtistUserType", buttonlist);
		buttonListMedium.map((data) => {
			formdata.append("ArtMedium", data);
		});

		formdata.append("FaceBookLink", values.facebook);
		formdata.append("InstagramLink", values.instagram);
		formdata.append("TwitterLink", values.twitter);
		formdata.append("PinterestLink", values.pinterest);
		formdata.append("LinkedInLink", values.linkedin);
		formdata.append("Website", values.website);
		formdata.append("BirthYear", birthYear ? birthYear : "");
		formdata.append("BirthCountry", birthCountry ? birthCountry : "");
		formdata.append("Quote", values.quote);
		formdata.append("Description", values.description);
		formdata.append("OverView", overView);
		let userToken = localStorage.getItem('authToken')
		putFormData(`UserManagment/api/Artists/UpdateArtistProfile_V2`, formdata, userToken).then((responseJson) => {
			if (responseJson.resultCode === 1) {
				// onLoad();
				if (buttonType === "next") {
					onTabChange("about");
				} else {
					popToast.show("Profile Update Successfully");
				}

				setIsLoading(false);
			} else {
				popToast.show(responseJson.error.message);
				setIsLoading(false);
			}
		});
	};
	const attach_image = useRef(null);

	// On select image
	const onImageSelect = (e, type) => {
		e.preventDefault();
		let file = e.target.files[0];
		e.target.value = null;
		if (file) {
			let reader = new FileReader();
			let image = reader.result;
			reader.onloadend = () => {
				let mediaObj = {
					media: file,
					mediaResult: reader.result,
				};
				setMedia(mediaObj);
			};
			reader.readAsDataURL(file);
		}
	};

	const updateEditorContent = (value) => {
		setOverView(value.Body);
	};

	const onEditorStateChange = (currentEditorState) => {

		setEditorState(currentEditorState);
	};

	return (
		<>
			<DisplayHeader userId={userId} getArtistDetails={getArtistDetails} />
			<div className="profile-tab-description">
				<p>
					Creating a detailed profile, including details of your artistic career, gives the collector a better perspective of your art,
					history and pricing and increases the chances of closing a sale. Please follow through the tabs to complete your profile. You can
					save and come back anytime to edit or add information.
				</p>
			</div>
			<div>
				<Formik
					enableReinitialize={true}
					initialValues={{
						fullName: artistDetails && artistDetails.name !== null ? artistDetails.name : "",
						aliasName: artistDetails && artistDetails.alaisName !== null ? artistDetails.alaisName : "",
						website: artistDetails && artistDetails.website !== null ? artistDetails.website : "",
						facebook: artistDetails && artistDetails.faceBookLink !== null ? artistDetails.faceBookLink : "",
						instagram: artistDetails && artistDetails.instagramLink !== null ? artistDetails.instagramLink : "",
						linkedin: artistDetails && artistDetails.linkedInLink !== null ? artistDetails.linkedInLink : "",
						pinterest: artistDetails && artistDetails.pinterestLink !== null ? artistDetails.pinterestLink : "",
						twitter: artistDetails && artistDetails.twitterLink !== null ? artistDetails.twitterLink : "",
						quote: artistDetails && artistDetails.quote !== null ? artistDetails.quote : "",
						description: artistDetails && artistDetails.description !== null ? artistDetails.description : "",
					}}
					validationSchema={Yup.object().shape({
						fullName: Yup.string()
							.matches(nonWhiteSpacesRegex, "Please enter your Full Name")
							.required("Please enter your Full Name"),
					})}
					onSubmit={(values, actions) => {
						console.log("🚀 ~ file: ProfileTab.js ~ line 205 ~ ProfileTab ~ actions", actions);
						onSubmitProfile(values);
						console.log(values);
					}}
				>
					{({ errors, touched, handleSubmit, setFieldValue, isSubmitting }) => {
						return (
							<Form name="deliveryAddress" method="post" className="profile-form-wrapper">
								<div className="about-form-section">
									<TitleHeading>{"Add/Edit your Full Name & Alias Name"}</TitleHeading>

									<FormRow>
										<Field name="fullName" component={FormikInput} type="text" label={"Full Name*"} />
									</FormRow>
									<FormRow>
										<Field
											name="aliasName"
											component={FormikInput}
											type="text"
											label={"Alias Name (displayed on the platform)"}
										/>
									</FormRow>
									<br />
									<TitleHeading>{"How should we quote you when we feature your profile? Add a quote."}</TitleHeading>
									<FormRow>
										<FormInput
											name="quote"
											component="input"
											maxlength="90"
											type="text"
										/>
									</FormRow>
									<p>{`Max allowed 90 characters`}</p>
									<br />
									<FormRow>
										<div style={{ position: "relative", width: "100%" }}>
											<SpanFloater>{"Your current base country"}</SpanFloater>
											<Field

												render={(field) => (
													<SelectInput
														{...field}
														name="place"
														style={{
															height: 45,
															marginTop: 15,
															width: "100%",
														}}
														value={baseCountry}
														optionFilterProp="children"
														showSearch
														onChange={(value) => setBaseCountry(value)}
													>
														{countriesList.map((data, index) => {
															return (
																<option key={`place${index}`} value={data.name}>
																	{data.name}
																</option>
															);
														})}
													</SelectInput>
												)}
											/>


										</div>
									</FormRow>
									<br />
									<TitleHeading>{"Add your Birth year & Birth country*"}</TitleHeading>
									<FormRow className="artist-profile-form-row">
										<div style={{ position: "relative", width: "50%", marginRight: 10 }}>
											<SpanFloater>{"Select Birth Year"}</SpanFloater>
											<Field

												render={(field) => (
													<SelectInput
														{...field}
														style={{
															height: 45,
															marginTop: 15,
															width: "100%",
														}}
														showSearch
														optionFilterProp="children"
														onChange={(value) => {
															setBirthYear(value);
														}}
														value={birthYear}
													>
														{yearList.map((year, index) => {
															return (
																<option key={`year${index}`} value={year}>
																	{year}
																</option>
															);
														})}
													</SelectInput>
												)}
											/>


										</div>
										<div style={{ position: "relative", width: "50%" }}>
											<SpanFloater>{"Select Birth Country"}</SpanFloater>
											<Field

												render={(field) => (
													<SelectInput
														{...field}
														style={{
															height: 45,
															marginTop: 15,
															width: "100%",
														}}
														optionFilterProp="children"
														showSearch
														onChange={(value) => {
															setBirthCountry(value);
														}}
														value={birthCountry}
													>
														{countriesList.map((data, index) => {
															return (
																<option key={`year${index}`} value={data.name}>
																	{data.name}
																</option>
															);
														})}
													</SelectInput>
												)}
											/>
										</div>
									</FormRow>
									<TitleHeading>
										{"Make a relevant statement about your art practice. Write a short paragraph for the busy collector having only 1 minute to read it."}
									</TitleHeading>
									<FormRow>
										<FormTextarea
											style={{
												width: "100%",
											}}
											rows="3"
											name="description"
											placeholder="Enter your paragraph within 300 characters or 30 words"
											component="textarea"
											maxlength="300"
										/>
									</FormRow>
									{/* <p>{`Word count:${wordCountShortPara}`}</p> */}

									<TitleHeading>{"Add a detailed Overview of your artistic career."}</TitleHeading>
									<FormRow>
										<Field
											name="articleBody"
											component={FormikEditor}
											className="overview-editor"
											id="enter-body"
											placeholder={"Add some content here"}
											editorState={editorState}
											defaultEditorState={editorState}
											value={overView}
											onEditorStateChange={onEditorStateChange}
											onContentStateChange={(contentState) =>
												updateEditorContent({ Body: draftToHtml(convertToRaw(editorState.getCurrentContent())) })
											}
											editorStyle={{
												height: "300px",
												width: "100%",
												border: "1px solid #E5E5E5",
												padding: "10px",
												fontFamily: "Montserrat, sans- serif",
												color: "black",
												fontWeight: "100",
											}}
										/>
									</FormRow>

								</div>
								<div className="about-wrapper-section">
									<TitleHeading>{"Add your Personal Website and Social Media links (URL)."}</TitleHeading>
									<SocialMediaItem>
										<div>
											<img src={assets.images.web} alt="social" />
										</div>

										<span>Website</span>
										<FormInput type="text" name="website" placeholder="https://www.example.com/" />
									</SocialMediaItem>
									<SocialMediaItem>
										<div>
											<img src={assets.images.facebookIcon} alt="social" />
										</div>

										<span>Facebook</span>
										<FormInput type="text" name="facebook" placeholder="https://www.facebook.com/" />
									</SocialMediaItem>
									<SocialMediaItem>
										<div>
											<img src={assets.images.instagramIcon} alt="social" />
										</div>

										<span>Instagram</span>
										<FormInput type="text" name="instagram" placeholder="https://www.instagram.com/" />
									</SocialMediaItem>
									<SocialMediaItem>
										<div>
											<img src={assets.images.twitterIcon} alt="social" />
										</div>

										<span>Twitter</span>
										<FormInput type="text" name="twitter" placeholder="https://www.twitter.com/" />
									</SocialMediaItem>
									<SocialMediaItem>
										<div>
											<img src={assets.images.pinterestIcon} alt="social" />
										</div>

										<span>Pinterest</span>
										<FormInput name="pinterest" component="input" type="text" placeholder="https://www.pinterest.com/" />
									</SocialMediaItem>
									<SocialMediaItem>
										<div>
											<img src={assets.images.linkedinIcon} alt="social" />
										</div>

										<span>linkedIn</span>
										<FormInput type="text" name="linkedin" placeholder="https://www.linkedin.com/" />
									</SocialMediaItem>
								</div>
								<div className="about-wrapper-section">
									<TitleHeading>
										{
											"Select your preferred Art Medium. You can choose up to three Media. If the Medium you are looking for is not available, drop us an email. "
										}
										<a href="mailto:curators@rtistiq.com"> Click to Mail</a>
									</TitleHeading>
									<ButtonSet
										buttons={mediumButtonTypes}
										selectedButtons={selectedMediumList}
										list={artistDetails ? artistDetails.artMedium : ""}
										allowedSelectionNo={3}
									/>
								</div>
								<div className="about-wrapper-section">
									<TitleHeading>
										{
											"Select the Style best describing your art. You can choose maximum five Styles. If your Style is not among the options, choose the nearest option and drop us an email."
										}
										<a href="mailto:curators@rtistiq.com"> Click to Mail</a>
									</TitleHeading>
									<ButtonSet
										buttons={buttonTypes}
										selectedButtons={selectedStyleList}
										list={artistDetails ? artistDetails.artistUserTypesIds : ""}
										allowedSelectionNo={5}
									/>
								</div>

								<div className="about-wrapper-section">
									<Tooltip
										placement="topLeft"
										title={`The Artwork image would be used to represent your profile in certain parts of the platform.`}
									>
										<TitleHeading>
											{"Upload any one Artwork Image, representing your most preferred style of work"}{" "}
											<img src={assets.images.info} alt="logo" />
										</TitleHeading>
									</Tooltip>
									<div className="art-image-wrapper">
										<AddNewProfileEventImageWrap onClick={(e) => attach_image.current.click()}>
											<AddNewEventImage
												notPlaceholder={media}
												src={media ? media.mediaResult : assets.images.addPhoto}
												alt="add"
											/>
											<input
												type="file"
												style={{ display: "none" }}
												accept=".bmp, .gif, .ico, .jpeg, .jpg, .png, .svg, .tif, .tiff, .jiff, .jfif, .pjpeg, .pjp"
												onChange={(e) => onImageSelect(e, "image")}
												ref={attach_image}
											/>
										</AddNewProfileEventImageWrap>
										{artistDetails && artistDetails.artworkImageUC !== null && (
											<img src={artistDetails.artworkImageUC} alt="add" className="uploaded-art-image" />
										)}
									</div>
								</div>

								<div className="submit-button-wrapper">
									<MainSubmitButton
										onClick={(e) => {
											setButtonType("save");
											handleSubmit(e);
										}}
									>
										{isLoading && buttonType === "save" ? "Updating.." : "Save"}
									</MainSubmitButton>

								</div>
							</Form>
						);
					}}
				</Formik>
			</div>
		</>
	);
};

export default ProfileTab;
