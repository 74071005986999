import * as types from "../actions/ActionsTypes";

const initialState = {
	categoryList: [],
	categoryLoader: false,

	isAlreadyLoaded: false,

	productId: "",
	slugId: "",
	isProductValid: null,
	productResponse: null,
	isMyProduct: null,

	// Basic
	categoryArray: [],
	categoryId: "",
	productName: "",
	artistId: "",
	artistName: "",
	tagsArray: [],
	tags: "",
	oneLineDescription: "",
	description: "",
	// Primary
	height: "",
	width: "",
	depth: "",
	medium: "",
	isLimitedEdition: false,
	materialsUsed: "",
	yearofCreation: "",
	weight: "",
	originalArtworkId: "",
	packageHeight: "",
	packageWidth: "",
	packageDepth: "",

	// Pricing and stock
	price: "",
	sellingPrice: "",
	shippingCharge: "",
	isHidePrice: false,
	stockManagement: true,
	stock: 1,
	currency: null,

	// Images and Videos
	imagesArray: [],
	// roomImageArray: [],
	existingImagesArray: [],
	// existingRoomImageArray: [],
	deletedFiles: [],
	displayPicture: "",
	displayPictureOriginal: "",
	downloadingImage: false,

	// Features
	productProperties: [],

	getPropertyLoader: false,
	getParentOfCategoryLoader: false,
	getProductLoader: false,
	updatingProductLoader: false,
	style: [],
	subject: [],
	seriesName: "",
	isAbstract: false,
	framed: false,
	editionPublisher: "",
	panel: false,
	packaging: "",
	status: 0,
	isMetaDataStatusUpdated: 0,
	productType: 0,
};

const editProductReducer = (state = initialState, { type, payload, error }) => {
	switch (type) {
		// ========== Get Category ========== //
		case types.GET_CATEGORY_REQUEST:
			state = {
				...state,
				categoryList: [],
				categoryLoader: true,
			};
			return state;
		case types.GET_CATEGORY_SUCCESS:
			state = {
				...state,
				categoryList: payload,
				categoryLoader: false,
			};
			return state;
		case types.GET_CATEGORY_FAILURE:
			state = {
				...state,
				categoryList: [],
				categoryLoader: false,
			};
			return state;

		// ========== Get Original Artworks ========== //
		case types.GET_ORIGINAL_ARTWORKS_REQUEST:
			state = {
				...state,
				originalArtworks: [],
				originalArtworksLoader: true,
			};
			return state;
		case types.GET_ORIGINAL_ARTWORKS_SUCCESS:
			state = {
				...state,
				originalArtworks: payload,
				originalArtworksLoader: false,
			};
			return state;
		case types.GET_ORIGINAL_ARTWORKS_FAILURE:
			state = {
				...state,
				originalArtworks: [],
				originalArtworksLoader: false,
			};
			return state;

		// ======= Get category properties ====== //
		case types.GET_CATEGORY_PROPERTIES_AND_MAP_REQUEST:
			state = {
				...state,
				getPropertyLoader: true,
				productProperties: [],
			};
			return state;
		case types.GET_CATEGORY_PROPERTIES_AND_MAP_SUCCESS:
			state = {
				...state,
				getPropertyLoader: false,
				productProperties: payload,
			};
			return state;
		case types.GET_CATEGORY_PROPERTIES_AND_MAP_FAILURE:
			state = {
				...state,
				getPropertyLoader: false,
				productProperties: [],
			};
			return state;

		// ======= Get Product ====== //
		case types.GET_PRODUCT_REQUEST:
			state = {
				...state,
				getProductLoader: true,
				isAlreadyLoaded: false,
				tagsArray: [],
				isProductValid: null,
			};
			return state;

		case types.GET_PRODUCT_SUCCESS:
			state = {
				...state,
				isAlreadyLoaded: true,
				productId: payload._id,
				slugId: payload.slugId,
				isProductValid: "VALID",
				isMyProduct: payload.isMyProduct,

				// Basic
				categoryId: payload.categoryId,
				productName: payload.productName,
				artistId: payload.artistId,
				artistName: payload.artistName,
				tagsArray: payload.tags ? payload.tags.split(",") || [...state.tagsArray, payload.tags] : [],
				tags: payload.tags,
				conditionReport: payload.conditionReport,
				signature: payload.signature,
				oneLineDescription: payload.oneLineDescription,
				description: payload.description,
				// Primary
				dimensions: payload.dimensions,
				height: (payload.dimensions && payload.dimensions.height) || 0,
				width: (payload.dimensions && payload.dimensions.width) || 0,
				depth: (payload.dimensions && payload.dimensions.depth) || 0,
				medium: payload.medium,
				isLimitedEdition: payload.isLimitedEdition,
				materialsUsed: payload.materialsUsed,
				yearofCreation: payload.yearofCreation,
				weight: payload.weight || 0,
				originalArtworkId: payload.originalArtworkId,
				packageHeight: (payload.packageDimensions && payload.packageDimensions.height) || 0,
				packageWidth: (payload.packageDimensions && payload.packageDimensions.width) || 0,
				packageDepth: (payload.packageDimensions && payload.packageDimensions.depth) || 0,

				// Pricing and stock
				price: payload.price,
				sellingPrice: payload.sellingPrice,
				shippingCharge: payload.shippingCharge.toString() || 0,
				isHidePrice: payload.isHidePrice,
				stockManagement: true,
				stock: payload.stockDetails && payload.stockDetails.length,
				currency: payload.currency,

				// Images and Videos
				imagesArray: [],
				// roomImageArray: [],
				existingImagesArray: payload.productImages || [],
				// existingRoomImageArray: payload.roomViewImage ? [payload.roomViewImage] : [],
				deletedFiles: [],
				displayPicture: payload.displayPicture,
				displayPictureOriginal: payload.displayPictureOriginal,
				style: payload.style,
				subject: payload.subject,
				seriesName: payload.seriesName,
				isAbstract: payload.isAbstract,
				framed: payload.framed,
				editionPublisher: payload.editionPublisher,
				panel: payload.panel,
				packaging: payload.packaging,
				status: payload.status,
				autoApproveQuote: payload.autoApproveQuote,
				autoRejectQuote: payload.autoRejectQuote,
				approvePercentage: payload.approvePercentage,
				rejectPercentage: payload.rejectPercentage,
				productType: payload.productType,
				subStatus: payload.subStatus,

				// Features

				getProductLoader: false,
			};
			return state;

		case types.GET_PRODUCT_FAILURE:
			state = {
				...state,
				getProductLoader: false,
				isAlreadyLoaded: false,
				isProductValid: "INVALID",
			};
			return state;

		// ======= Get Parent List Array ====== //
		case types.GET_PARENTS_BY_CATEGORY_REQUEST:
			return {
				...state,
				getParentOfCategoryLoader: true,
				categoryArray: [],
			};

		case types.GET_PARENTS_BY_CATEGORY_SUCCESS:
			state = {
				...state,
				getParentOfCategoryLoader: false,
				categoryArray: payload,
			};
			return state;

		case types.GET_PARENTS_BY_CATEGORY_FAILURE:
			return {
				...state,
				getParentOfCategoryLoader: false,
				categoryArray: [],
			};

		// ======= Download display picture ====== //
		case types.DOWNLOAD_DISPLAY_PICTURE_REQUEST:
			return {
				...state,
				downloadingImage: true,
			};

		case types.DOWNLOAD_DISPLAY_PICTURE_SUCCESS:
			state = {
				...state,
				downloadingImage: false,
			};
			return state;

		case types.DOWNLOAD_DISPLAY_PICTURE_FAILURE:
			return {
				...state,
				downloadingImage: false,
			};

		// ========= Save Each Stage Data to Reducer ========= //
		case types.SET_PRODUCT_DETAILS:
			state = {
				...state,
				// TODO: Convert with spread
				categoryArray: payload.categoryArray,
				categoryId: payload.categoryId,
				productName: payload.productName,
				artistId: payload.artistId,
				tagsArray: payload.tagsArray,
				tags: payload.tags,
				conditionReport: payload.conditionReport,
				signature: payload.signature,
				description: payload.description,
				oneLineDescription: payload.oneLineDescription,
				// Primary
				height: payload.height || 0,
				width: payload.width || 0,
				depth: payload.depth || 0,
				medium: payload.medium,
				isLimitedEdition: payload.isLimitedEdition,
				materialsUsed: payload.materialsUsed,
				yearofCreation: payload.yearofCreation,
				weight: payload.weight || 0,
				originalArtworkId: payload.originalArtworkId,
				packageHeight: payload.packageHeight || 0,
				packageWidth: payload.packageWidth || 0,
				packageDepth: payload.packageDepth || 0,

				price: payload.price,
				sellingPrice: payload.sellingPrice,
				shippingCharge: payload.shippingCharge || 0,
				isHidePrice: payload.isHidePrice,
				stock: payload.stock,
				currency: payload.currency,

				subject: payload.subject,
				style: payload.style,
				panel: payload.panel,
				framed: payload.frame,
				isAbstract: payload.isAbstract,
				seriesName: payload.seriesName,
				editionPublisher: payload.editionPublisher,
				subStatus: payload.subStatus,

				productProperties: payload.productProperties,
			};
			return state;

		case types.SET_IMAGE_DETAILS:
			state = {
				...state,
				imagesArray: payload.imagesArray,
				// roomImageArray: payload.roomImageArray,
				existingImagesArray: payload.existingImagesArray,
				// existingRoomImageArray: payload.existingRoomImageArray,
				deletedFiles: payload.deletedFiles || [],
				displayPicture: payload.displayPicture || null,
			};
			return state;

		// ======= Update Product ====== //
		case types.UPDATE_PRODUCT_REQUEST:
			state = {
				...state,
				updatingProductLoader: true,
			};
			return state;
		case types.UPDATE_PRODUCT_SUCCESS:
			state = {
				...state,
				updatingProductLoader: false,
			};
			return state;
		case types.UPDATE_PRODUCT_FAILURE:
			state = {
				...state,
				updatingProductLoader: false,
			};
			return state;
		case types.META_DATA_ARTWORK_UPDATE:
			state = {
				...state,
				isMetaDataStatusUpdated: 0,
			};
			return state;
		case types.META_DATA_ARTWORK_UPDATE_SUCCESS:
			state = {
				...state,
				isMetaDataStatusUpdated: 1,
			};
			return state;
		case types.META_DATA_ARTWORK_UPDATE_FAILURE:
			state = {
				...state,
				isMetaDataStatusUpdated: 2,
			};
			return state;
		case types.CLEAR_UPDATE_PRODUCT_REDUCER_STATES:
			state = {
				...initialState,
			};
			return state;
		default:
			return state;
	}
};

export default editProductReducer;
