/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from "react";


import { get, isEmpty, isNull } from "lodash";
import assets from "../../../../../assets";

import { SelectInput, SpanFloater } from "../../ArtistProfile.styled";
import { popToast } from "../../../../../components/Toast/Toast";
import { postFormData, putFormData } from "../../../../../util/apiConfig";
import FloatingLabeledInput from "../../../../../components/FloatingLabeledInput/FloatingLabeledInput";
import StyledButton from "../../../../../components/StyledButton/StyledButton";
import { AddNewEventBody, AddNewEventDateWrapper, AddNewEventFooter, AddNewEventImage, AddNewEventImageWrap } from "../EventContainer/AddNewEventModal/AddNewEventModal.styled";
const ExhibitionsNewModal = ({ onLoad, data, type, onToggleModel, userId }) => {

	const [values, setValues] = useState();
	const [media, setMedia] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const year = new Date().getFullYear();
	const yearList = Array.from(new Array(121), (val, index) => year - index);

	// On change inputs
	const updateValues = (newValue) => {
		setValues({ ...values, ...newValue });
	};

	useEffect(() => {
		if (type === "edit") {
			updateValues({ title: data.name, location: data.location, year: data.year, institution: data.institution, type: data.type });
			let mediaObj = {
				media: "",
				mediaResult: data.attachments[0],
			};
			setMedia(mediaObj);
		} else {
			updateValues({ title: "", location: "", year: "", institution: "", type: "" });
		}

		if (yearList) {
			var yearOption = [];
			yearList.map((year, index) => {
				yearOption.push({
					name: year,
					value: year,
				});
			});
		}
	}, []);

	const attach_image = useRef(null);

	// On select image
	const onImageSelect = (e, type) => {
		e.preventDefault();
		let file = e.target.files[0];
		// e.target.value = null;
		if (file) {
			let reader = new FileReader();
			reader.onloadend = () => {
				let mediaObj = {
					media: file,
					mediaResult: reader.result,
				};
				setMedia(mediaObj);
			};
			reader.readAsDataURL(file);
		}
	};

	const isValidForm = () => {
		if (isEmpty(values.title)) {
			popToast.show("Title is mandatory", "error");
			return false;
		} else if (!values.type) {
			popToast.show("Type is mandatory", "error");
			return false;
		} if (!values.year) {
			popToast.show("Year is mandatory", "error");
			return false;
		} else if (isEmpty(values.institution)) {
			popToast.show("Institution is mandatory", "error");
			return false;
		} else if (isEmpty(values.location)) {
			popToast.show("Location is mandatory", "error");
			return false;
		} else if (!media) {
			popToast.show("Please upload a image", "error");
			return false;
		}
		return true;
	}

	const onClickSave = () => {
		if (isValidForm()) {
			setIsLoading(true);

			// values.eventImage = media.media;
			console.log(values);

			var formdata = new FormData();
			formdata.append("ExhibitionImages", !isNull(media) ? media.media : "");
			formdata.append("Year", values.year ? values.year : "");
			formdata.append("Location", values.location);
			formdata.append("Institution", values.institution ? values.institution : "");
			formdata.append("Name", values.title ? values.title : "");
			formdata.append("Type", values.type);
			let token = localStorage.getItem('authToken');
			if (type === "edit") {
				putFormData(`UserManagment/api/Artists/UpdateExhibhitions?ExhibitionID=${data._id}`, formdata, token).then((responseJson) => {
					if (responseJson.resultCode === 1) {

						popToast.show("Updated Successfully");
						updateValues({ title: "", location: "", year: "", institution: "", type: "" });
						onLoad();
						onToggleModel();
					} else {
						popToast.show(responseJson.error.message);
					}
					setIsLoading(false);
				});
			} else {
				postFormData(`UserManagment/api/Artists/AddExhibitions?ManagedArtistId=${userId}`, formdata, token).then((responseJson) => {
					if (responseJson.resultCode === 1) {

						popToast.show("Added Successfully");
						updateValues({ title: "", location: "", year: "", institution: "", type: "" });
						onLoad();
						onToggleModel();
					} else {
						popToast.show(responseJson.error.message);
					}
					setIsLoading(false);
				});
			}
		}
	};

	return (
		<>
			<AddNewEventBody>
				<AddNewEventImageWrap onClick={(e) => attach_image.current.click()}>
					<AddNewEventImage notPlaceholder={media} src={media ? media.mediaResult : assets.images.addPhoto} alt="add" />
					<input
						type="file"
						style={{ display: "none" }}
						accept=".bmp, .gif, .ico, .jpeg, .jpg, .png, .svg, .tif, .tiff, .jiff, .jfif, .pjpeg, .pjp"
						onChange={(e) => onImageSelect(e, "image")}
						ref={attach_image}
					/>
				</AddNewEventImageWrap>

				<FloatingLabeledInput label={"Title*"} value={get(values, "title", "")} onChange={(e) => updateValues({ title: e.target.value })} />
				<AddNewEventDateWrapper>
					{/* <FloatingLabeledInput
                        label={t("Type")}
                        value={get(values, "type", "")}
                        onChange={e => updateValues({ type: e.target.value })}
                    /> */}

					<div style={{ position: "relative", width: "50%", marginRight: 10 }}>
						<SpanFloater>{"Type*"}</SpanFloater>
						<SelectInput
							style={{
								height: 40,
								width: "100%",
								marginTop: 15,
							}}
							showSearch
							optionFilterProp="children"
							value={values && values.type}
							onChange={(e) => {
								updateValues({ type: e });
							}}
						>
							<option value="Solo">Solo</option>
							<option value="Group">Group</option>
							<option value="Biennale">Biennale</option>
						</SelectInput>
					</div>

					<div style={{ position: "relative", width: "50%" }}>
						<SpanFloater>{"Year"}</SpanFloater>
						<SelectInput
							style={{
								height: 40,
								width: "100%",
								marginTop: 15,
							}}
							showSearch
							optionFilterProp="children"
							value={get(values, "year", "")}
							onChange={(e) => {
								updateValues({ year: e });
							}}
						>
							{yearList.map((year, index) => {
								return (
									<option key={`year${index}`} value={year}>
										{year}
									</option>
								);
							})}
						</SelectInput>
					</div>
				</AddNewEventDateWrapper>
				<FloatingLabeledInput
					label={"Institution"}
					value={get(values, "institution", "")}
					onChange={(e) => updateValues({ institution: e.target.value })}
				/>
				<FloatingLabeledInput
					label={"Location*"}
					value={get(values, "location", "")}
					onChange={(e) => updateValues({ location: e.target.value })}
				/>
			</AddNewEventBody>
			<AddNewEventFooter>
				<StyledButton
					loading={isLoading}
					disabled={isLoading}
					variant="invert"
					height="40px"
					width="10rem"
					fontSize="14px"
					margin="auto 0"
					onClick={() => onClickSave()}
				>
					{isLoading ? "Saving" : "Save"}
				</StyledButton>
			</AddNewEventFooter>
		</>
	);
};
export default ExhibitionsNewModal;
